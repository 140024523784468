import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField } from '@s_mart/form';
import { required } from '@s_mart/rules';

import { ButtonTipoGarantia } from '../../fields.styles';

const FieldGarantiaPaineis = () => {
  const { setValue, watch } = useFormContext();

  const _formValuesTipoGarantiaPaineis = watch('tipoGarantiaPaineis');

  return (
    <TextField
      name='garantiaPaineis'
      label='Garantia'
      value={watch('garantiaPaineis')}
      placeholder='20'
      InputProps={{
        endAdornment: (
          <ButtonTipoGarantia
            variant='neutral'
            onClick={() => setValue('tipoGarantiaPaineis', _formValuesTipoGarantiaPaineis === 0 ? 1 : 0)}
          >
            {_formValuesTipoGarantiaPaineis === 0 ? 'Meses' : 'Anos'}
          </ButtonTipoGarantia>
        ),
      }}
      required
      mask='numero'
      rules={required}
    />
  );
};

export default memo(FieldGarantiaPaineis);
